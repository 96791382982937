import pino from 'pino';

const pinoConfig = {
    browser: {
        asObject: true,
    },
};

const pinoLogger = pino(pinoConfig);


export class Logger {
    option: { caller: string };
    constructor(className: string) {
        this.option = {
            caller: className
        }
    }
    error = (message: string, option?: object) => {
        return pinoLogger.error({ ...this.option, ...option}, message);
    }
    warn = (message: string, option?: object) => {
        return pinoLogger.warn({ ...this.option, ...option}, message);
    }
    debug = (message: string, option?: object) => {
        return pinoLogger.debug({ ...this.option, ...option}, message);
    }
    info = (message: string, option?: object) => {
        return pinoLogger.info({ ...this.option, ...option}, message);
    }
}
