import axios from "axios";
import * as newrelic from "./newrelic-browser"
import {Logger} from "./logger";

const logger = new Logger('util/front/api')

type ResponseUserData = {
    userId?: number
    profile?: {
        user?: {
            id?: number
        }
    }
}

export const get = async <T>(path: string, options?: object): Promise<T> => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const url = (process.env.NEXT_PUBLIC_BK_DIRECT === 'true' ? process.env.NEXT_PUBLIC_API_URL : '') + process.env.NEXT_PUBLIC_API_PREFIX + path
    try {
        const response = await axios.get(url, {...options, withCredentials: true})
        if (response.status === 401) {
            console.error(response)
        }
        return response.data as T
    } catch (error: unknown) {
        const err = new Error(`GET ${url} is faled`)
        logger.error(err.message, { error })
        throw err
    }
}

export const post = async <T>(path: string, object?: object, options?: object, needRedirect: boolean = true): Promise<T | undefined> => {
    const url = process.env.NEXT_PUBLIC_API_PREFIX + path
    const _object = { ...object }
    try {
        const response = await axios.post(url, _object, {...options, withCredentials: true})
        if (response.status === 401) {
            console.error(response)
        }
        const userData = response.data as ResponseUserData
        if (newrelic !== undefined && userData?.userId !== undefined) {
            newrelic.setCustomAttribute('enduser.id', userData.userId)
        }
        if (newrelic !== undefined && userData?.profile?.user?.id !== undefined) {
            newrelic.setCustomAttribute('enduser.id', userData?.profile?.user?.id)
        }
        return response.data as T
    } catch (e: unknown) {
        console.error(e)
        const statusData = e as { response?: { status: number | string | boolean}}
        if (statusData?.response?.status) {
            needRedirect && (window.location.href = '/')
        } else {
            console.error(e)
        }
        return undefined;
    }
}