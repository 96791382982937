export const setCustomAttribute = (key: string, value: newrelic.SimpleType) => {
    if (window.newrelic !== undefined) {
        window.newrelic.setCustomAttribute(key, value)
    }
}

export const addPageAction = (key: string, attributes?: Record<string, newrelic.SimpleType>) => {
    if (window.newrelic !== undefined) {
        window.newrelic.addPageAction(key, attributes)
    }
}

export const noticeError = (error: string | Error, attributes?: Record<string, newrelic.SimpleType>) => {
    if (window.newrelic !== undefined) {
        window.newrelic.noticeError(error, attributes)
    }
}