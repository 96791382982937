import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DashboardIcon from '@mui/icons-material/Dashboard';
import React, {useCallback, useEffect} from "react";
import { parseCookies } from 'nookies'
import {AppBar} from "../molecules/AppBar";
import {GlobalTitle} from "../molecules/GlobalTitle";
import {Drawer} from "../molecules/Drawer";
import {LinkMenuItem} from "../molecules/LinkMenuItem";
import * as newrelic from "../utils/newrelic-browser";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {isIndex} from "../utils/utils";
import Button from "@mui/material/Button";
import {useRouter} from "next/router";
import Dialog from "@mui/material/Dialog";
import UserDetailCard from "./UserDetailCard";
import {LayoutShift} from "../types";

export const GlobalNavigation = () => {

    const router = useRouter();
    const [isLoginPage, setIsLoginPage] = React.useState(true);
    const [openUserDetail, setOpenUserDetail] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    useEffect(()=>{
        setTimeout(()=> {
            new PerformanceObserver((entryList) => {
                let worstElement: LayoutShift | undefined = undefined
                for (const entry  of entryList.getEntries()) {
                    const cls = entry as LayoutShift
                    if (!worstElement || worstElement.value < cls.value) {
                        worstElement = cls
                    }
                }
                if (!!worstElement && worstElement.value > 0.001) {
                    const souceCount = worstElement.sources?.length ?? 0
                    const clsEvent = {
                        startTime: worstElement.startTime,
                        lastInputTime: worstElement.lastInputTime,
                        value: worstElement.value,
                        source1Tag: souceCount > 0 ? worstElement.sources[0].node?.tagName : '',
                        source1ClassName: souceCount > 0 ? worstElement.sources[0].node?.className : '',
                        source1InnerText50: souceCount > 0 ? worstElement.sources[0].node?.innerText?.substring(0, 50) : '',
                        source2Tag: souceCount > 1 ? worstElement.sources[1].node?.tagName : '',
                        source2ClassName: souceCount > 1 ? worstElement.sources[1].node?.className : '',
                        source2InnerText50: souceCount > 1 ? worstElement.sources[1].node?.innerText?.substring(0, 50) : '',
                    }
                    //logger.info(`CLS candidate : ${JSON.stringify(clsEvent)}`);
                    newrelic.addPageAction('CLSCandidate', clsEvent)
                }
            }).observe({type: 'layout-shift', buffered: true});
        }, 2000)
        const cookies = parseCookies()
        newrelic.setCustomAttribute('userId', cookies.userId || -1)
    }, [])
    useEffect(()=> {
        setIsLoginPage(isIndex(router.pathname))
    }, [router.pathname, setIsLoginPage])

    const handleOpenUserDetail = useCallback(() => {
        setOpenUserDetail(true)
    }, [setOpenUserDetail])

    const handleCloseUserDetail = useCallback(() => {
        setOpenUserDetail(false)
    }, [setOpenUserDetail])

    return (<>
        <AppBar
                sx={{
                    backgroundColor: '#1d252c',
                    borderBottom: '1px solid #1d252c'
        }}>
            <Toolbar sx={{ display: 'flex'}}>
                <GlobalTitle />
                {!isLoginPage && (<Button onClick={handleOpenUserDetail}>プロファイル</Button>)}

            </Toolbar>
        </AppBar>
        { isLoginPage ? (<></>) : (
        <Drawer variant="permanent" open={open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
            </Toolbar>
            <Divider />
            <List component="nav">
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon onClick={toggleDrawer}>
                                <MenuIcon />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                <LinkMenuItem href={'/dashboard'} label={'Dashboard'} icon={<DashboardIcon />} />
            </List>
        </Drawer>)}
        <Dialog open={openUserDetail}
                onClose={handleCloseUserDetail}>
            <UserDetailCard />
        </Dialog>

        </>)
}