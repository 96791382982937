import * as React from 'react';
import Typography from '@mui/material/Typography';
import {SxProps, Theme} from "@mui/system";

interface TitleProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>
}

export default function Title(props: TitleProps) {
  return (
    <Typography component="h2" variant="h6"
                sx={{fontWeight: 'bold', color: "#565d64", ...props.sx}}
                gutterBottom>
      {props.children}
    </Typography>
  );
}
