import React from 'react';
import {createTheme} from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from '@mui/material/Box';
import {GlobalNavigation} from "../organisms/GlobalNavigation";
import {roboto} from "../theme";

const theme = createTheme({
    palette: {
        primary: {
            main: "#099800",
            light: "#5fd553",
            dark: "#028200",
        },
    },
    typography: {
        fontFamily: 'sans-serif,' + 'Noto Sans JP,' + roboto.style.fontFamily,
    },
})

export const MainTemplate = ({ children }: {
    children: React.ReactNode
}) =>{

    return (
            <ThemeProvider theme={theme}>
                <Box sx={{ display: 'flex', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                    <CssBaseline />
                    <GlobalNavigation />
                    <Box
                        component="main"
                        id="main"
                        sx={{
                            marginTop: '64px',
                            flexGrow: 1,
                            overflow: 'auto',
                            backgroundColor: '#fafafa'
                        }}
                    >
                        <Box sx={{position: 'relative'}}>
                            {children}
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        )
}