import * as React from 'react';
import Title from '../atoms/Title';
import Paper from "@mui/material/Paper";
import {TextField} from "@mui/material";
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {ErrorInfo, InputField, MessageInfo} from "../types";
import {createRef, useCallback, useEffect, useRef, useState} from "react";
import debounce from "@mui/utils/debounce";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from "@mui/material/CircularProgress";
import {ErrorFeedBack} from "./ErrorFeedback";
import Box from "@mui/material/Box";
import {SavedFeedBack} from "./SavedFeedback";

interface InputCardProps<T> {
    id: string,
    title: string
    fields: InputField<T>[]
    onAction: (fields: InputField<T>[]) => void | Promise<void>
    actionLabel?: string
    heightFixed?: boolean
    savedMessage?: string
    noDialog?: boolean
    design?: string
}

export const InputCard = <T, >({
    id, title, fields, onAction,  actionLabel= '保存', heightFixed = true, savedMessage = '保存しました', noDialog = false, design = 'Paper'}: InputCardProps<T>) => {

    const [privateFields, setPrivateFields] = useState<InputField<T>[]>([])
    const [error, setError] = useState<ErrorInfo>()
    const [saved, setSaved] = useState<MessageInfo>()
    const elementsRef = useRef(fields.map(() => createRef()));
    const [waitForAction, setWaitForAction] = useState<boolean>(false)

    useEffect(() => {
        const newFields = JSON.parse(JSON.stringify(fields)) as InputField<T>[]
        newFields.forEach((field, index) => field.ref = elementsRef.current[index] as React.Ref<HTMLInputElement>)
        setPrivateFields(newFields)
    }, [fields, elementsRef])

    const handleChange = debounce(useCallback((propName: string, value: T) => {
        privateFields.forEach(field => delete field.ref)
        const newFields = JSON.parse(JSON.stringify(privateFields)) as InputField<T>[]
        newFields.forEach((field, index) => field.ref = elementsRef.current[index] as React.Ref<HTMLInputElement>)
        const field = newFields.find(field => field.propName === propName)
        if (!field) {
            return
        }
        field.value = value
        setPrivateFields(newFields)
    }, [privateFields, setPrivateFields]))

    const renderField = (field: InputField<T>) => {
        return field.type === 'text' ?
            <TextField
                required
                key={field.propName}
                label={field.label}
                defaultValue={field.value}
                margin="normal"
                onChange={(event) => handleChange(field.propName, event.target.value as T)}
                inputRef={field.ref}
                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => { field.actionOnEnter === true && handleKeyDown(event)}}
            />
            : field.type === 'password' ?
                <TextField
                    required
                    type='password'
                    key={field.propName}
                    label={field.label}
                    defaultValue={field.value}
                    margin="normal"
                    onChange={(event) => handleChange(field.propName, event.target.value as T)}
                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => { field.actionOnEnter === true && handleKeyDown(event)}}
                    inputRef={field.ref}
                />
                : field.type === 'number' ?
                    <TextField
                        required
                        type={'number'}
                        key={field.propName}
                        label={field.label}
                        defaultValue={field.value}
                        margin="normal"
                        onBlur={(event) => handleChange(field.propName, event.target.value as T)}
                        inputRef={field.ref}
                    />
                    : field.type === 'date' ?
                        <LocalizationProvider key={field.propName} dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                key={field.propName}
                                label="Date desktop"
                                slotProps={{
                                    textField: {
                                        helperText: 'YYYY-MM-DD',
                                    },
                                }}
                                value={dayjs(field.value as string)}
                                onChange={(value) => value && handleChange(field.propName, value.format("YYYY-MM-DD") as T)}
                                /*renderInput={(params) => <TextField {...params} />}*/
                                inputRef={field.ref}
                            />
                        </LocalizationProvider>
                        : field.type === 'select' ?
                            <TextField
                                required
                                select
                                key={field.propName}
                                label={field.label}
                                defaultValue={field.value}
                                margin="normal"
                                onChange={(event) => handleChange(field.propName, event.target.value as T)}
                                inputRef={field.ref}
                            >
                                {field.options?.map(option => <MenuItem key={`input-option-${option.id}`}
                                                                        value={option.id}>{option.name}</MenuItem>)}
                            </TextField>
                            : <></>
    }

    const handleSave = debounce(useCallback(async () => {
        setWaitForAction(true)
        privateFields.forEach((field: InputField<T>) => {
            if (!field.value) {
                const refObj = field.ref as React.RefObject<HTMLInputElement>
                if (refObj?.current?.value !== undefined) {
                    field.value = refObj.current.value as T
                }
            }
        })
        const emptyRequiredField = privateFields.find(field => field.required && (field.value === undefined || field.value === ''))
        if (emptyRequiredField !== undefined) {
            const error: ErrorInfo = {
                message: `${emptyRequiredField.label} は必須項目です。入力してください`,
                timestamp: new Date().getTime(),
            }
            setError(error)
        } else {
            await onAction(privateFields)
        }
        setSaved({ message: savedMessage, timestamp: new Date().getTime()})
        setWaitForAction(false)
    }, [onAction, privateFields, setError, elementsRef, setWaitForAction, setSaved]))


    const handleKeyDown = debounce(useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.nativeEvent.isComposing || e.key !== 'Enter') return
        void handleSave()
    }, [handleSave]))

    if (design === 'Box') {
        return (
            <Box
                id={id}
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: heightFixed ? '240px' : 'inherit',
                    letterSpacing: '2px',
                    position: 'relative',
                }}
            >
                <Title>{title}</Title>
                {privateFields.map((field) => renderField(field))}
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <Button variant="contained" onClick={handleSave} disabled={waitForAction}>{actionLabel}</Button>
                <ErrorFeedBack feedbackText={error?.message || ''} savedTimestamp={error?.timestamp || 0}/>
                { noDialog === false && (<SavedFeedBack feedbackText={saved?.message || ''} savedTimestamp={saved?.timestamp || 0} />)}
                {waitForAction && (
                    <Box sx={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        backgroundColor: "rgba(51,51,51,0.34)"
                    }}>
                        <Box sx={{width: "40px", height: "40px", margin: 'auto'}}><CircularProgress/></Box>
                    </Box>
                )}
            </Box>
        );
    } else {
        return (
            <Paper
                id={id}
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: heightFixed ? '240px' : 'inherit',
                    letterSpacing: '2px',
                    position: 'relative'
                }}
            >
                <Title>{title}</Title>
                {privateFields.map((field) => renderField(field))}
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <Button variant="contained" onClick={handleSave} disabled={waitForAction}>{actionLabel}</Button>
                <ErrorFeedBack feedbackText={error?.message || ''} savedTimestamp={error?.timestamp || 0}/>
                { noDialog === false && (<SavedFeedBack feedbackText={saved?.message || ''} savedTimestamp={saved?.timestamp || 0} />)}
                {waitForAction && (
                    <Box sx={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        backgroundColor: "rgba(51,51,51,0.34)"
                    }}>
                        <Box sx={{width: "40px", height: "40px", margin: 'auto'}}><CircularProgress/></Box>
                    </Box>
                )}
            </Paper>
        );
    }
}
